import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setBrandingCSSLoaded } from 'redux/modules/appConfigs';
import {getBrandingCssVersion, getLanguage} from 'redux/modules/appConfigs/selectors';

const CssInjection = () => {
  const dispatch = useDispatch();

  const language = useSelector(getLanguage);
  const brandingCssVersion = useSelector(getBrandingCssVersion);

  useEffect(() => {
    dispatch(setBrandingCSSLoaded(false));
  }, [language]);

  return brandingCssVersion && language ? (
    <link
      rel="stylesheet"
      key={`${brandingCssVersion}_${language}`}
      href={`${
        window.environmentConfig?.baseUrl || process.env.REACT_APP_BASE_URL
      }/customer/api/css/${brandingCssVersion}/branding.min.css?language=${language}`}
      onLoad={() => dispatch(setBrandingCSSLoaded(true))}
    />
  ) : null;
};

export default CssInjection;
