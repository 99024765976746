import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import NavSkeleton from 'components/Skeleton/NavSkeleton';
import AllSportsButton from 'components/SportsSection/partials/AllSportsButton';
import ListItem from 'components/SportsSection/partials/ListItem';
import NavItemName from 'components/SportsSection/partials/NavItemName';
import ParentItem from 'components/SportsSection/partials/ParentItem';
import { FAVORITES_TYPES, GAME_BETTING } from 'constants/app';
import { TYPE_TO_URL } from 'constants/competitions';
import { MARKET_TYPES } from 'constants/marketTypes';
import {
  getAppDevice,
  getDesktopSettingsCompetitionViewSports,
  getIsCompetitionViewEnabled,
  getLanguage,
  getTimezone,
  getTranslation
} from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchGetEvents, resetSportData, setRace } from 'redux/modules/competitions';
import {
  getEventData,
  getEventDataLoading,
  getEventNameHighlighted,
  getEventNameTranslations,
  getEventParents
} from 'redux/modules/competitions/selectors';
import { getStatusByMarketPricesId } from 'redux/modules/marketsPrices/selectors';
import { MarketStatus, SportId } from 'types';
import { filterCompetitionViewParents } from 'utils/navigation';

const EventView = () => {
  const { sportId, eventId, marketId = '' } = useParams();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const device = useSelector(getAppDevice);
  const timezone = useSelector(getTimezone);
  const domainLanguage = useSelector(getTranslation);
  const language = useSelector(getLanguage);
  const isCompetitionViewEnabled = useSelector(getIsCompetitionViewEnabled);
  const competitionViewSports = useSelector(getDesktopSettingsCompetitionViewSports);
  const status = useSelector(getStatusByMarketPricesId(marketId));
  const eventParents = useSelector(getEventParents);
  const eventData = useSelector(getEventData);
  const eventNameTranslations = useSelector(getEventNameTranslations);
  const eventLoading = useSelector(getEventDataLoading);
  const eventNameHighlighted = useSelector(getEventNameHighlighted);

  const isTennis = sportId === SportId.TENNIS;
  const isMobile = device === Devices.MOBILE;
  const parents = filterCompetitionViewParents(eventParents, competitionViewSports, sportId, isCompetitionViewEnabled);
  const isDailyGoals = eventData.filter(data => data.marketType === MARKET_TYPES.dailyGoals).length;
  const lastParent = parents && parents.at(-1);
  const isGroupItem = lastParent && lastParent.type === FAVORITES_TYPES.group;
  const lastParentIsGamesBetting = isGroupItem && isTennis && lastParent && lastParent.name === GAME_BETTING;
  const hasFavoritesIcon = !isDailyGoals && !lastParentIsGamesBetting;

  useEffect(() => {
    if (eventId && sportId) {
      dispatch(fetchGetEvents({ eventId, showGroups: isTennis, updateParents: true }));
    }
  }, [eventId, marketId, sportId, isTennis, timezone, isLoggedIn, language]);

  useEffect(() => {
    return () => {
      dispatch(setRace(null));
      dispatch(resetSportData());
    };
  }, []);

  const getHeaderName = () => {
    return eventNameTranslations;
  };

  return (
    <>
      {eventLoading && <NavSkeleton withDelay={150} itemsCount={40} />}
      <div className={classNames({ hidden: eventLoading })}>
        {isMobile && !eventLoading && <AllSportsButton />}
        {parents.map(parent => (
          <ParentItem key={parent.id} item={parent} />
        ))}
        {eventNameTranslations && (
          <NavItemName
            type={TYPE_TO_URL.EVENT}
            highlighted={eventNameHighlighted}
            nameTranslations={getHeaderName()}
            hasFavoritesIcon={hasFavoritesIcon}
          />
        )}
        {status !== MarketStatus.CLOSED &&
          eventData.map(d => <ListItem textLeft item={d} domainLanguage={domainLanguage} key={d.id} />)}
      </div>
    </>
  );
};

export default EventView;
