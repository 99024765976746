import { useEffect, useRef } from 'react';
import BodyClassName from 'react-body-classname';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import LandingPageRedirect from 'components/LandingPageRedirect';
import { COLLAPSE_NAVIGATION_COOKIE_KEY } from 'components/LeftNavigation/HideMenuButton';
import LoadingWithOverlay from 'components/LoadingWithOverlay';
import ResponsibleBettingModals from 'components/ResponsibleBettingModals';
import BrowserRoutes from 'components/Routes';
import Tooltip from 'components/Tooltip';
import {
  BannerNames,
  BroadCastChannels,
  BroadCastHandledTypes,
  MOBILE_MAX_DISPLAY_SIZE,
  ONLY_MOBILE_URLS,
  PostMessageTypes,
  RESPONSIBLE_BETTING_SELF_EXCLUSION_PAGE_URL
} from 'constants/app';
import {
  ASIAN_VIEW_AUTO_COLLAPSED_RIGHT_SIDE_WIDTH,
  ASIAN_VIEW_MOBILE_MIN_WIDTH,
  ASIAN_VIEW_MOBILE_WIDTH
} from 'constants/asianView';
import {
  ACCOUNT_BASE_URL,
  ASIAN_BASE_URL,
  ASIAN_MOBILE_BET_LIST_URL,
  GAMES_BASE_URL,
  HOME_BASE_URL,
  MOBILE_LOGIN_BASE_URL,
  MY_BETS_BASE_URL,
  SEARCH_BASE_URL
} from 'constants/locations';
import { MyBetsLocations } from 'constants/myBets';
import useDevice from 'hooks/useDevice';
import { useTimeLimit } from 'hooks/useTimeLimit';
import AsianLeagueFilterInjection from 'injections/AsianLeagueFilterInjection';
import BalanceInjection from 'injections/BalanceInjection';
import CashOutStatusesIntervalsInjection from 'injections/CashOutStatusesIntervalsInjection';
import CssInjection from 'injections/CssInjection';
import CssOperatorInjection from 'injections/CssOperatorInjection';
import CurrencyAndResponsibleBettingSettingsInjection from 'injections/CurrencyAndResponsibleBettingSettingsInjection';
import CurrentBetsInjection from 'injections/CurrentBetsInjection';
import FontsInjection from 'injections/FontsInjection';
import PageHeightInjection from 'injections/PageHeightInjection';
import ResponsibleBettingCountersInjection from 'injections/ResponsibleBettingCountersInjection';
import WebSocketsSubscriptionsInjection from 'injections/WebSocketsSubscriptionsInjection';
import { AxiosInterceptorsInjection } from 'redux/api/request';
import {
  fetchAppConfigs,
  fetchContentPages,
  setBackendContent,
  setTimezone,
  setUserDevice
} from 'redux/modules/appConfigs';
import {
  getBackendPages,
  getBrandingCSSLoaded,
  getDesktopSettingsCollapseSportsBar,
  getFallbackLang,
  getIsAsianViewEnabled,
  getIsIframeEnabled,
  getIsPropertiesLoaded,
  getLanguage
} from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { fetchAppSettings, setAccountPageReferer } from 'redux/modules/appSettings';
import { getParentDomain } from 'redux/modules/appSettings/selectors';
import { setIsAutoCollapsedRightSide, setIsLandscapeAsianView, setIsMobileAsianView } from 'redux/modules/asianView';
import { proceedLogout, setAfterLogOutRedirect } from 'redux/modules/auth';
import { getLoggedInStatusState, getLogoutRedirectUrl } from 'redux/modules/auth/selectors';
import { fetchBanner } from 'redux/modules/banners';
import { BannerSections } from 'redux/modules/banners/type';
import { cleanInlineSelectedBets } from 'redux/modules/inlinePlacement';
import { PagesFromBackend } from 'redux/modules/pages/types';
import { getIsShowingCustomLinkPopup } from 'redux/modules/popup/selectors';
import { setCustomerSelfExclusionStartDate } from 'redux/modules/responsibleBetting';
import {
  getIsResponsibleBettingLoaded,
  getIsSelfExclusionEnabled,
  getIsTimeOutEnabled,
  getResponsibleBettingSettings
} from 'redux/modules/responsibleBetting/selectors';
import { fetchTooltips } from 'redux/modules/tooltip';
import { fetchUserInfo } from 'redux/modules/user';
import { getIsUserInfoLoaded, getOperatorCurrency } from 'redux/modules/user/selectors';
import { CookieNames } from 'types';
import {
  clearAllTimers,
  convertTimezoneToDropdown,
  getOperatorDomain,
  getOperatorLanguage,
  iosBundleRedirect,
  redirectToOperator
} from 'utils';
import { setIOSBundleInitialCookies } from 'utils/cookie';
import { getDefaultTimezoneOffset, getTimezoneCookieName } from 'utils/date';
import { dispatchEvent, sendIframeMessages, subscribeOnIframeMessages } from 'utils/iframe';
import { getEnvironmentRootPath } from 'utils/navigation';
import { getSelfExclusionPageType } from 'utils/responsibleBetting';
import useReminder from 'hooks/useReminder';

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { sportId = '' } = useParams();

  const isBrandingCSSLoaded = useSelector(getBrandingCSSLoaded);
  const isUserInfoLoaded = useSelector(getIsUserInfoLoaded);
  const isResponsibleBettingLoaded = useSelector(getIsResponsibleBettingLoaded);
  const logoutRedirectUrl = useSelector(getLogoutRedirectUrl);
  const language = useSelector(getLanguage);
  const fallbackLang = useSelector(getFallbackLang);
  const asianViewEnabled = useSelector(getIsAsianViewEnabled);
  const iframeEnabled = useSelector(getIsIframeEnabled);
  const collapseSportsBar = useSelector(getDesktopSettingsCollapseSportsBar);
  const isShowingCustomLinkPopup = useSelector(getIsShowingCustomLinkPopup);
  const operatorCurrency = useSelector(getOperatorCurrency);
  const isPropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const parentDomain = useSelector(getParentDomain);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const pages = useSelector(getBackendPages);
  const rbSettings = useSelector(getResponsibleBettingSettings);
  const isSelfExclusionEnabled = useSelector(getIsSelfExclusionEnabled);
  const isTimeOutEnabled = useSelector(getIsTimeOutEnabled);

  const selfExclusionEnabled = rbSettings?.rgTools?.selfExclusionEnabled ?? false;
  const selfExclusionEndDate = rbSettings?.rgTools?.selfExclusionEndDate ?? 0;
  const selfExcludedThroughApi = rbSettings?.selfExcludedThroughApi ?? false;
  const selfExcludedThroughApiUntil = rbSettings?.selfExcludedThroughApiUntil ?? 0;
  const timeLimitAvailable = rbSettings?.rgTools?.timeLimitAvailable ?? 0;

  const iframeDomainsConfig = window?.environmentConfig?.iframeDomain || '';
  const iframeDomains = iframeDomainsConfig.split(',');
  const isAppReady = iframeDomainsConfig === '' || (iframeDomainsConfig !== '' && iframeDomains.includes(parentDomain));
  const isRoutesReady =
    isAppReady && isPropertiesLoaded && isUserInfoLoaded && (!isLoggedIn || (isLoggedIn && isResponsibleBettingLoaded));

  const divRef = useRef<HTMLDivElement>(null);
  const searchScrollPosition = useRef<number>(0);

  const timezoneCookieName = getTimezoneCookieName();

  const selfExclusionPageUrl = pages[PagesFromBackend.RG_TOOLS_SELF_EXCLUSION_START];

  const [cookies, setCookie, removeCookie] = useCookies([
    COLLAPSE_NAVIGATION_COOKIE_KEY,
    timezoneCookieName,
    CookieNames.LANGUAGE
  ]);

  const convertedData = convertTimezoneToDropdown();
  const timezoneCookie = cookies[timezoneCookieName] || getDefaultTimezoneOffset();
  const selectedOffset = convertedData.find(item => item.value === `${timezoneCookie}`);
  const isClosedNavbar = cookies[COLLAPSE_NAVIGATION_COOKIE_KEY] === 'true' && collapseSportsBar;

  const { currentDevice, isMobile, isAsianViewPage } = useDevice();

  const handleResize = () => {
    const maxDisplaySize = isAsianViewPage ? ASIAN_VIEW_MOBILE_WIDTH : MOBILE_MAX_DISPLAY_SIZE;
    const deviceToCheck = window.innerWidth > maxDisplaySize ? Devices.DESKTOP : Devices.MOBILE;

    if (deviceToCheck === Devices.DESKTOP && ONLY_MOBILE_URLS.includes(location.pathname)) {
      if (location.pathname === ASIAN_MOBILE_BET_LIST_URL) {
        navigate(`${ASIAN_BASE_URL}${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}/matched/1`);
      } else {
        navigate(isAsianViewPage ? ASIAN_BASE_URL : HOME_BASE_URL);
      }
    }

    const device = window.innerWidth > MOBILE_MAX_DISPLAY_SIZE ? Devices.DESKTOP : Devices.MOBILE;

    dispatch(setUserDevice(device));
    dispatch(setIsMobileAsianView(window.innerWidth <= ASIAN_VIEW_MOBILE_WIDTH));
    dispatch(
      setIsLandscapeAsianView(
        window.innerWidth >= ASIAN_VIEW_MOBILE_MIN_WIDTH && window.innerWidth <= ASIAN_VIEW_MOBILE_WIDTH
      )
    );
    dispatch(setIsAutoCollapsedRightSide(window.innerWidth < ASIAN_VIEW_AUTO_COLLAPSED_RIGHT_SIDE_WIDTH));
  };

  useTimeLimit();
  useReminder();

  useEffect(() => {
    if (window.top == window.self && iframeEnabled && !iosBundleRedirect) {
      const operatorLanguage = getOperatorLanguage(cookies.BIAB_LANGUAGE);
      const operatorDomain = getOperatorDomain(operatorLanguage);

      redirectToOperator(operatorDomain);
    }
  }, [iframeEnabled]);

  useEffect(() => {
    const setSearchScrollPosition = () => {
      searchScrollPosition.current = window.scrollY;
    };

    if (location.pathname === SEARCH_BASE_URL) {
      window.scrollTo({ top: searchScrollPosition.current });

      window.addEventListener('scroll', setSearchScrollPosition);
    } else {
      window.scrollTo(0, 0);
      if (divRef.current) divRef.current.scrollTo({ top: 0 });
    }

    window.addEventListener('resize', handleResize);
    window.history.scrollRestoration = 'manual';

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', setSearchScrollPosition);
    };
  }, [location.pathname]);

  useEffect(() => {
    handleResize();

    setIOSBundleInitialCookies(setCookie, location.search);
    subscribeOnIframeMessages(setCookie, removeCookie, dispatch, { fallbackLang }, currentDevice);
  }, []);

  useEffect(() => {
    if (isAppReady && isPropertiesLoaded) {
      dispatch(fetchUserInfo(true));
    }
  }, [dispatch, operatorCurrency, isAppReady, isPropertiesLoaded]);

  useEffect(() => {
    if (isAppReady) {
      dispatch(fetchContentPages());
      dispatch(fetchAppConfigs());
    }
  }, [currentDevice, dispatch, isAppReady]);

  useEffect(() => {
    if (language && isAppReady) {
      dispatch(fetchAppSettings(language));
      dispatch(
        fetchBanner({
          name: BannerNames.logo,
          locale: language,
          section: BannerSections.LOGO,
          platform: currentDevice
        })
      );
    }
  }, [currentDevice, language, dispatch, isAppReady]);

  useEffect(() => {
    if (isLoggedIn && isSelfExclusionEnabled) {
      const pageType = getSelfExclusionPageType(
        selfExclusionEndDate,
        selfExclusionEnabled,
        selfExcludedThroughApi,
        selfExcludedThroughApiUntil
      );

      dispatch(setBackendContent(pageType));
    }

    if (!selfExclusionPageUrl && location.pathname.includes(RESPONSIBLE_BETTING_SELF_EXCLUSION_PAGE_URL)) {
      if (!isLoggedIn && !logoutRedirectUrl?.includes(RESPONSIBLE_BETTING_SELF_EXCLUSION_PAGE_URL)) {
        navigate(isAsianViewPage ? ASIAN_BASE_URL : HOME_BASE_URL);
        dispatch(setCustomerSelfExclusionStartDate(null));
      }
    }
  }, [rbSettings, isLoggedIn, isSelfExclusionEnabled, location.pathname]);

  useEffect(() => {
    if (isLoggedIn && !location.pathname.includes(ACCOUNT_BASE_URL) && isTimeOutEnabled && !isSelfExclusionEnabled) {
      dispatch(setBackendContent(PagesFromBackend.RG_TOOLS_TIME_LIMIT_END));
    }
  }, [timeLimitAvailable, location.pathname, isLoggedIn]);

  useEffect(() => {
    if (logoutRedirectUrl) {
      navigate(logoutRedirectUrl);
      dispatch(setAfterLogOutRedirect(''));
    }
  }, [logoutRedirectUrl]);

  useEffect(() => {
    if (language) {
      dispatch(fetchTooltips({ locale: language, platform: currentDevice, asianViewEnabled }));
    }
  }, [language, currentDevice, dispatch, asianViewEnabled]);

  useEffect(() => {
    if (selectedOffset && selectedOffset.value) {
      if (!cookies[timezoneCookieName]) {
        setCookie(timezoneCookieName, selectedOffset.value, { path: '/' });
      }

      dispatch(setTimezone(selectedOffset.value));
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (!sportId?.startsWith('tc')) {
      const currentUrl = `${window.location.origin}${getEnvironmentRootPath()}${location.pathname}`;

      if (iframeEnabled) {
        sendIframeMessages({ type: PostMessageTypes.ROUTE_CHANGE, href: currentUrl });
      } else {
        dispatchEvent({ type: PostMessageTypes.ROUTE_CHANGE, href: currentUrl });
      }
    }
  }, [location.pathname, iframeEnabled, currentDevice, sportId]);

  useEffect(() => {
    if ((iframeEnabled && iframeDomainsConfig === '') || iframeDomainsConfig !== '') {
      sendIframeMessages({ type: PostMessageTypes.APP_LOADED });
    }
  }, [iframeEnabled, iframeDomainsConfig]);

  useEffect(() => {
    dispatch(cleanInlineSelectedBets());
    if (!location.pathname.includes(ACCOUNT_BASE_URL) && !location.pathname.includes(MOBILE_LOGIN_BASE_URL)) {
      dispatch(setAccountPageReferer(location.pathname));
    }
  }, [location.pathname]);

  useEffect(() => {
    const channel = new BroadcastChannel(BroadCastChannels.USER_ACTIONS);
    channel.onmessage = event => {
      if (event.data.type === BroadCastHandledTypes.LOGOUT) {
        clearAllTimers();
        dispatch(proceedLogout(event.data.payload));
      }
    };
  }, []);

  return (
    <BodyClassName
      className={classNames('app_container', {
        biab_mobile: isMobile,
        disableScroll: isShowingCustomLinkPopup
      })}
    >
      <div
        ref={divRef}
        id="biab_body"
        className={classNames('biab_body biab_fluid biab_no-bottom-menu', {
          biab_mobileView: isMobile,
          'biab_minimized-nav': isClosedNavbar,
          biab_game:
            location.pathname.startsWith(`${GAMES_BASE_URL}`) || location.pathname.startsWith(MyBetsLocations.MyBets),
          biab_asianView: isAsianViewPage
        })}
      >
        {isPropertiesLoaded && <LandingPageRedirect />}
        <CssInjection />
        <CssOperatorInjection />
        <WebSocketsSubscriptionsInjection />
        <BalanceInjection />
        {!iosBundleRedirect && <FontsInjection />}
        <AxiosInterceptorsInjection />
        <CurrentBetsInjection />
        <CurrencyAndResponsibleBettingSettingsInjection />
        <ResponsibleBettingCountersInjection />
        <PageHeightInjection />
        <CashOutStatusesIntervalsInjection />
        <AsianLeagueFilterInjection />
        {isRoutesReady && <BrowserRoutes />}
        {isBrandingCSSLoaded && !isRoutesReady && <LoadingWithOverlay />}
        <Tooltip />
        <ResponsibleBettingModals />
      </div>
    </BodyClassName>
  );
}

export default App;
