import { ChangeEvent } from 'react';
import { NumericFormat } from 'react-number-format';
import classNames from 'classnames';

import commonStyles from 'components/ResponsibleBetting/styles.module.scss';

import styles from './styles.module.scss';

interface ResponsibleBettingInputFieldProps {
  value: number | null;
  placeholder: string;
  label: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  isMobile: boolean;
  error?: boolean;
  disabled?: boolean;
}

const ResponsibleBettingInputField = ({
  value,
  label,
  placeholder,
  onChange,
  onFocus,
  isMobile,
  error = false,
  disabled = false
}: ResponsibleBettingInputFieldProps) => (
  <div
    className={classNames(styles.container, {
      [styles.container__mobile]: isMobile
    })}
  >
    <label className={styles.label}>{label}</label>
    <NumericFormat
      className={classNames(commonStyles.input, styles.container__input, {
        [commonStyles.input__with_error]: error,
        [commonStyles.input__mobile]: isMobile
      })}
      placeholder={placeholder}
      disabled={disabled}
      value={value || ''}
      onChange={onChange}
      onFocus={onFocus}
      decimalScale={1}
      allowNegative={false}
    />
  </div>
);
export default ResponsibleBettingInputField;
