import { PropsWithChildren, useEffect, useMemo } from 'react';
import BodyClassName from 'react-body-classname';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import AsianViewMobileBetsPlaced from 'components/AsianViewMobileBetsPlaced';
import AsianViewMobileHeader from 'components/AsianViewMobileHeader';
import AsianViewRGModalMessage from 'components/AsianViewPageModule/components/AsianViewRGModalMessage';
import AsianViewQuickBetsPlaced from 'components/AsianViewQuickBetsPlaced';
import AsianFooter from 'components/Footer/AsianFooter';
import AsianHeader from 'components/Header/AsianHeader';
import LoadingWithOverlay from 'components/LoadingWithOverlay';
import LogInPopup from 'components/LogInPopup';
import MobileToolbar from 'components/MobileToolbar';
import {
  ASIAN_OUTRIGHT_COMPETITION_ID,
  ASIAN_OUTRIGHT_MARKET_ID,
  ASIAN_POPULAR_LINK_COMPETITION_ID,
  ASIAN_POPULAR_LINK_SPORT_ID,
  ASIAN_SINGLE_VIEW_COMPETITION_ID,
  ASIAN_SINGLE_VIEW_EVENT_ID,
  ASIAN_SINGLE_VIEW_SPORT_ID,
  ASIAN_VIEW_LEAGUES_FILTER,
  AsianViewMarketLink,
  AsianViewTimeFilter,
  AsianViewTimeFrame,
  AV_QUERY_LENGTH_TO_DISPLAY_SEARCH_CONTENT
} from 'constants/asianView';
import { AV_OUTRIGHT_FROM_BET_LIST_MOBILE_QUERY_PARAM } from 'constants/betList';
import { ASIAN_BASE_URL, ASIAN_VIEW_CASH_OUT_URL } from 'constants/locations';
import { SEARCH_QUERY_LENGTH } from 'constants/search';
import { PARAMS_ASIAN_SEARCH_KEY } from 'constants/urlParams';
import useLeaguesFilters from 'hooks/useLeaguesFilters';
import BackendContentPage from 'pages/BackendContentPage';
import { setBackendContent } from 'redux/modules/appConfigs';
import {
  getAsianViewMobileNavigationMenu,
  getBackendContentPage,
  getBackendPages,
  getBrandingCSSLoaded,
  getIsPropertiesLoaded,
  getPositionOfAsianViewMobileNavigationMenu,
  getPropertiesLoading
} from 'redux/modules/appConfigs/selectors';
import { setOddsType } from 'redux/modules/appSettings';
import { getOddsType } from 'redux/modules/appSettings/selectors';
import { fetchAsianSports } from 'redux/modules/asianSportsNavigation';
import { closeAsianViewProfitLossTablePopUp, setDaysValue, setIsAsianViewNotFoundView } from 'redux/modules/asianView';
import {
  getAVProfitLossTablePopUpEventId,
  getIsAllAsianViewCompetitionsClosed,
  getIsAsianViewNotFoundView,
  getIsLandscapeAsianView,
  getIsMobileAsianView
} from 'redux/modules/asianView/selectors';
import { fetchAsianViewFavourites } from 'redux/modules/asianViewFavourites';
import { fetchLeagues, setCurrentParams, setIsLeaguesLoaded } from 'redux/modules/asianViewLeaguesFilter';
import { getLeaguesCurParams, getLeaguesIsLoaded } from 'redux/modules/asianViewLeaguesFilter/selectors';
import { closeAllMobileWidgets } from 'redux/modules/asianViewWidgets';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getClosedMarketsIds } from 'redux/modules/marketsPrices/selectors';
import { fetchPageData } from 'redux/modules/pages';
import { PagesFromBackend } from 'redux/modules/pages/types';
import { getIsSelfExclusionEnabled, getIsTimeOutEnabled } from 'redux/modules/responsibleBetting/selectors';
import { getUserAsianViewOddsType } from 'redux/modules/user/selectors';
import { CookieNames, OddsType, SportId } from 'types';
import { getIsValidMarketLink, getIsValidTimeFilter } from 'utils/asianView';

import AsianViewContent from './components/AsianViewContent';
import AsianViewMobileSingleViewHeader from './components/AsianViewMobileSingleViewHeader';
import AsianViewProfitLossTablePopUp from './components/AsianViewProfitLossTablePopUp';

import styles from './styles.module.scss';

interface IAsianViewLayout {
  withoutNavigation?: boolean;
  withoutMobileHeader?: boolean;
  isCashOutPage?: boolean;
  withoutFooter?: boolean;
  isNotFoundPage?: boolean;
}

const AsianViewLayout = ({
  children,
  withoutNavigation = false,
  withoutMobileHeader = false,
  isCashOutPage = false,
  withoutFooter = false,
  isNotFoundPage = false
}: PropsWithChildren<IAsianViewLayout>) => {
  const dispatch = useDispatch();
  const { sportId = SportId.SOCCER, timeFilter, marketLink, timeFrame } = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [cookies] = useCookies([CookieNames.ODDS_TYPE]);

  const propertiesLoading = useSelector(getPropertiesLoading);
  const isBrandingCSSLoaded = useSelector(getBrandingCSSLoaded);
  const isLandscapeView = useSelector(getIsLandscapeAsianView);
  const isMobileAsianView = useSelector(getIsMobileAsianView);
  const backendContentPage = useSelector(getBackendContentPage);
  const curLeaguesParam = useSelector(getLeaguesCurParams);
  const closedMarketIds = useSelector(getClosedMarketsIds);
  const isAllLeaguesClosed = useSelector(getIsAllAsianViewCompetitionsClosed(closedMarketIds));
  const isPropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const isLeaguesLoaded = useSelector(getLeaguesIsLoaded);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const asianViewMobileNavigationMenu = useSelector(getAsianViewMobileNavigationMenu);
  const positionOfAsianViewMobileNavigationMenu = useSelector(getPositionOfAsianViewMobileNavigationMenu);
  const isAsianViewNotFoundView = useSelector(getIsAsianViewNotFoundView);
  const pages = useSelector(getBackendPages);
  const plTablePopUpEventId = useSelector(getAVProfitLossTablePopUpEventId);
  const selectedOddsType = useSelector(getOddsType);
  const userAsianViewOddsType = useSelector(getUserAsianViewOddsType);
  const isSelfExclusionEnabled = useSelector(getIsSelfExclusionEnabled);
  const isTimeOutEnabled = useSelector(getIsTimeOutEnabled);

  const singleViewFilters = useLeaguesFilters();

  const hasTopMenu = isLandscapeView || (positionOfAsianViewMobileNavigationMenu && asianViewMobileNavigationMenu);
  const oddsTypeCookie = cookies[CookieNames.ODDS_TYPE];
  const singleViewCompetitionId = searchParams.get(ASIAN_SINGLE_VIEW_COMPETITION_ID);
  const singleViewEventId = searchParams.get(ASIAN_SINGLE_VIEW_EVENT_ID);
  const singleViewSportId = searchParams.get(ASIAN_SINGLE_VIEW_SPORT_ID);
  const isSingleView = (!!singleViewCompetitionId || !!singleViewEventId) && !!singleViewSportId;
  const outrightMarketId = searchParams.get(ASIAN_OUTRIGHT_MARKET_ID);
  const outrightCompetitionId = searchParams.get(ASIAN_OUTRIGHT_COMPETITION_ID);
  const isSingleOutrightView = !!outrightCompetitionId;
  const popularLinkCompetitionId = searchParams.get(ASIAN_POPULAR_LINK_COMPETITION_ID);
  const popularLinkSportId = searchParams.get(ASIAN_POPULAR_LINK_SPORT_ID);
  const isPopularLinkView = !!popularLinkCompetitionId && !!popularLinkSportId;
  const query = searchParams.get(PARAMS_ASIAN_SEARCH_KEY) || '';
  const querySearch = query.length >= SEARCH_QUERY_LENGTH && query;
  const savedLeagues = localStorage.getItem(ASIAN_VIEW_LEAGUES_FILTER);
  const noSingleView = !isPopularLinkView && !isSingleView && !isSingleOutrightView;
  const isOutrightFromBetListMobile = searchParams.get(AV_OUTRIGHT_FROM_BET_LIST_MOBILE_QUERY_PARAM) === 'true';
  const stringifiedSingleViewFilters = JSON.stringify(singleViewFilters);

  const leaguesParam = useMemo(() => {
    return savedLeagues ? JSON.parse(savedLeagues)?.[curLeaguesParam]?.ids ?? [] : [];
  }, [curLeaguesParam, savedLeagues]);

  const hasBottomMenu =
    isMobileAsianView && asianViewMobileNavigationMenu && !hasTopMenu && (isOutrightFromBetListMobile || noSingleView);

  useEffect(() => {
    const savedOddsType = userAsianViewOddsType || oddsTypeCookie || OddsType.DEC;

    if (savedOddsType !== selectedOddsType) {
      dispatch(setOddsType(userAsianViewOddsType || oddsTypeCookie || OddsType.DEC));
    }
  }, [userAsianViewOddsType, oddsTypeCookie]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchAsianViewFavourites());
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (
      isMobileAsianView &&
      marketLink &&
      marketLink !== AsianViewMarketLink.HDP_AND_OU &&
      marketLink !== AsianViewMarketLink.OUTRIGHT
    ) {
      navigate(
        `${ASIAN_BASE_URL}/sport/${sportId}/timeFilter/${timeFilter || AsianViewTimeFilter.Today}/marketLink/${
          AsianViewMarketLink.HDP_AND_OU
        }`,
        { replace: true }
      );
    }

    if (!isMobileAsianView) {
      dispatch(setDaysValue(AsianViewTimeFrame.ALL));
    }
  }, [isMobileAsianView, marketLink]);

  useEffect(() => {
    if (timeFrame && !isMobileAsianView) {
      navigate(
        `${ASIAN_BASE_URL}/sport/${sportId}/timeFilter/${timeFilter || AsianViewTimeFilter.Today}/marketLink/${
          marketLink || AsianViewMarketLink.HDP_AND_OU
        }`,
        { replace: true }
      );
    }
  }, [isMobileAsianView, timeFrame]);

  useEffect(() => {
    if (backendContentPage) {
      dispatch(setBackendContent(''));
    }
  }, [location.pathname]);

  useEffect(() => {
    if (marketLink && sportId && timeFilter && isMobileAsianView) {
      dispatch(setCurrentParams(`${sportId}-${marketLink}-${timeFilter}`));

      if (isAllLeaguesClosed) {
        dispatch(setIsLeaguesLoaded(false));
      }

      dispatch(
        fetchLeagues({
          id: sportId,
          payload: {
            marketLink: marketLink as AsianViewMarketLink,
            timeFilter: timeFilter as AsianViewTimeFilter,
            ...singleViewFilters
          }
        })
      );
    }
  }, [marketLink, sportId, timeFilter, isAllLeaguesClosed, isMobileAsianView, stringifiedSingleViewFilters, dispatch]);

  useEffect(() => {
    if (isMobileAsianView) {
      dispatch(closeAllMobileWidgets());
    }
  }, [marketLink, timeFilter, timeFrame, isMobileAsianView]);

  useEffect(() => {
    const isNotValidTimeFilter = !timeFilter || !getIsValidTimeFilter(timeFilter);
    const isNotValidMarketLink = !marketLink || !getIsValidMarketLink(marketLink);

    if (
      isPropertiesLoaded &&
      (querySearch ||
        isCashOutPage ||
        (isLeaguesLoaded && curLeaguesParam === `${sportId}-${marketLink}-${timeFilter}`) ||
        isNotValidTimeFilter ||
        isNotValidMarketLink)
    ) {
      dispatch(
        fetchAsianSports({
          timeFilter: isNotValidTimeFilter ? AsianViewTimeFilter.Today : (timeFilter as AsianViewTimeFilter),
          timeFrame: AsianViewTimeFrame.ALL,
          competitions: isCashOutPage ? [] : leaguesParam
        })
      );
    }
  }, [
    leaguesParam,
    isLeaguesLoaded,
    isPropertiesLoaded,
    timeFilter,
    curLeaguesParam,
    !!querySearch,
    dispatch,
    isCashOutPage
  ]);

  useEffect(() => {
    if (isNotFoundPage) {
      dispatch(fetchPageData(pages[PagesFromBackend.PAGE_NOT_FOUND] as string));
      dispatch(setIsAsianViewNotFoundView(true));
    } else if ((!timeFilter || !marketLink) && isAsianViewNotFoundView) {
      dispatch(setIsAsianViewNotFoundView(false));
    }
  }, [isNotFoundPage]);

  useEffect(() => {
    if (timeFilter && marketLink && (!getIsValidTimeFilter(timeFilter) || !getIsValidMarketLink(marketLink))) {
      dispatch(fetchPageData(pages[PagesFromBackend.PAGE_NOT_FOUND] as string));
      dispatch(setIsAsianViewNotFoundView(true));
    } else if (isAsianViewNotFoundView && !isNotFoundPage) {
      dispatch(setIsAsianViewNotFoundView(false));
    }
  }, [timeFilter, marketLink]);

  useEffect(() => {
    if (timeFilter && marketLink && getIsValidTimeFilter(timeFilter) && getIsValidMarketLink(marketLink)) {
      dispatch(setIsAsianViewNotFoundView(false));
    }
  }, [
    popularLinkSportId,
    popularLinkCompetitionId,
    outrightCompetitionId,
    outrightMarketId,
    singleViewSportId,
    singleViewEventId,
    singleViewCompetitionId
  ]);

  useEffect(() => {
    if (!isMobileAsianView && location.pathname === ASIAN_VIEW_CASH_OUT_URL && !timeFilter) {
      navigate(
        { pathname: `${ASIAN_VIEW_CASH_OUT_URL}/${AsianViewTimeFilter.Today}`, search: location.search },
        { replace: true }
      );
    }
  }, [isMobileAsianView, location.pathname]);

  useEffect(() => {
    return () => {
      dispatch(closeAsianViewProfitLossTablePopUp());
    };
  }, []);

  useEffect(() => {
    if (query.length >= AV_QUERY_LENGTH_TO_DISPLAY_SEARCH_CONTENT) {
      dispatch(closeAsianViewProfitLossTablePopUp());
    }
  }, [query]);

  if (propertiesLoading || !isBrandingCSSLoaded) {
    return <LoadingWithOverlay />;
  }

  return (
    <>
      <BodyClassName className={classNames({ disableScroll: !isMobileAsianView })} />
      <div className={classNames(styles.asianWrapper, { [styles.asianWrapper__classicNavigation]: hasBottomMenu })}>
        <AsianHeader isSelfExcluded={isSelfExclusionEnabled} />
        {isMobileAsianView && !withoutMobileHeader && !isSelfExclusionEnabled && !isTimeOutEnabled && (
          <>
            {(isSingleView || isPopularLinkView || isSingleOutrightView) &&
            !isAsianViewNotFoundView &&
            !isOutrightFromBetListMobile ? (
              <AsianViewMobileSingleViewHeader />
            ) : (
              <AsianViewMobileHeader />
            )}
          </>
        )}
        {backendContentPage && backendContentPage !== PagesFromBackend.PAGE_NOT_FOUND ? (
          <div>
            <BackendContentPage />
            <AsianFooter />
          </div>
        ) : (
          <>
            <AsianViewContent
              withoutNavigation={withoutNavigation}
              isCashOutPage={isCashOutPage}
              withoutFooter={withoutFooter}
            >
              {children}
            </AsianViewContent>
            {hasBottomMenu && <MobileToolbar />}
          </>
        )}
      </div>
      <LogInPopup />
      <AsianViewQuickBetsPlaced />
      <AsianViewMobileBetsPlaced />
      {plTablePopUpEventId && isLoggedIn && <AsianViewProfitLossTablePopUp />}
      <AsianViewRGModalMessage />
    </>
  );
};

export default AsianViewLayout;
